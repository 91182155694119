import {Subject, debounceTime} from 'rxjs'
import {useRef, useCallback} from 'react'

const useDebounceTyping = (duration, removeTypingUser, getConversation) => {
    const debouncers = useRef(new Map());
    const createDebouncer = useCallback(() => {
        const subject = new Subject();
        subject
            .pipe(debounceTime(duration))
            .subscribe(({ conversationId, userId }) => {
            // Stop subject
            subject.complete();
            // Remove debouncer from collection
            const deb = debouncers.current;
            const conversationItem = deb.get(conversationId);
            if (conversationItem) {
                conversationItem.delete(userId);
                // Cleanup. Remove conversation if it doesn't contain any users
                if (conversationItem.size === 0) {
                    deb.delete(conversationId);
                }
            }
            // Remove typing user from conversation
            removeTypingUser(conversationId, userId);
        });
        return subject;
    }, [debouncers, duration, removeTypingUser]);

    return (conversationId, userId) => {
        const deb = debouncers.current;
        const conversationItem = deb.get(conversationId);
        if (conversationItem) {
            // Conversation exists - searching for user
            const userItem = conversationItem.get(userId);
            if (userItem) {
                // User found - debounce
                userItem.next({ conversationId, userId });
            }
            else {
                // User not found - create a debouncer
                const subject = createDebouncer();
                conversationItem.set(userId, subject);
            }
        }
        else {
            // Conversation not found - create new and a deboucer
            const subject = createDebouncer();
            const newEntry = new Map();
            newEntry.set(userId, subject);
            deb.set(conversationId, newEntry);
            subject.next({ conversationId, userId });
        }
    };
};
export default useDebounceTyping