const chatChangeLogInStatus = (status) => ({
  type: 'CHAT_CHANGE_LOGIN_STATUS',
  status
});
const chatAddActiveUser = (user) => ({
  type: 'CHAT_ADD_ACTIVE_USER',
  user
});

const chatAddUsers = (allUsers) => ({
  type: 'CHAT_ADD_USERS',
  allUsers,
});

const chatAddUser = (user) => ({
  type: 'CHAT_ADD_USER',
  user
});
const chatAddActiveConversation = (conversation) => ({
  type: 'CHAT_ADD_ACTIVE_CONVERSATION',
  conversation
});

const chatAddConversation = (conversation) => ({
  type: 'CHAT_ADD_CONVERSATION',
  conversation
});

const chatAddMessage = (conversationId, newMessages) => ({
  type: 'CHAT_ADD_MESSAGE',
  newMessages,
  conversationId
});

const chatSetCurrentMessage = (message) => ({
  type: 'CHAT_SET_CURRENT_MESSAGE',
  message,
});

const chatSetLastMessage = (conversationId, message, userId) => ({
  type: 'CHAT_SET_LAST_MESSAGE',
  conversationId,
  message,
  userId,
});

const chatSetUnreadCountConversation = (conversationId, count) => ({
  type: 'CHAT_SET_UNREAD_COUNT_CONVERSATION',
  conversationId,
  count,
});

const chatSetUnreadCountActiveConversation = (count) => ({
  type: 'CHAT_SET_UNREAD_COUNT_ACTIVE_CONVERSATION',
  count,
});

const chatAddTypingUser = (conversationId, userId) => ({
  type: 'CHAT_ADD_TYPING_USER',
  conversationId,
  userId,
});

const chatRemoveTypingUser = (conversationId, userId) => ({
  type: 'CHAT_REMOVE_TYPING_USER',
  conversationId,
  userId,
});

export {
  chatChangeLogInStatus,
  chatAddActiveUser,
  chatAddUser,
  chatAddActiveConversation,
  chatAddConversation,
  chatAddMessage,
  chatSetCurrentMessage,
  chatSetLastMessage,
  chatSetUnreadCountConversation,
  chatSetUnreadCountActiveConversation,
  chatAddTypingUser,
  chatRemoveTypingUser,
  chatAddUsers,
}