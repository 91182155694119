import antd_es from "antd/lib/locale/es_ES";

const locale = {
  ...antd_es,
  locale: "eu",
  Pagination: {
    ...antd_es.Pagination,
  },
  DatePicker: {
    ...antd_es.DatePicker,
    lang: {
      ...antd_es.DatePicker.lang,
      locale: 'eu_ES',
      rangePlaceholder: ['Hasiera data', 'Bukaera data'],
      today: 'Gaur',
      now: 'Orain',
      ok: 'Ados'
    }
  },
  TimePicker: {
    ...antd_es.TimePicker,
  },
  Calendar: {
    ...antd_es.Calendar,
    lang: {
      ...antd_es.Calendar.lang,
      locale: 'eu_ES',
      rangePlaceholder: ['Hasiera data', 'Bukaera data'],
      today: 'Gaur',
      now: 'Orain',
      ok: 'Ados'
    }
  },
  global: { 
    ...antd_es.global,
  },
  Table: {
    ...antd_es.Table,
    triggerDesc: 'Ordenatu kopuru handienetik txikienera',
    triggerAsc: 'Ordenatu kopuru txikienetik handienera',
    cancelSort: 'Ezeztatu ordenatzea'
  },
  Modal: {
    ...antd_es.Modal
  },
  Popconfirm: {
    ...antd_es.Popconfirm
  },
  Transfer: {
    ...antd_es.Transfer
  },
  Upload: {
    ...antd_es.Upload
  },
  Empty: {
    ...antd_es.Empty
  },
  Icon: {
    ...antd_es.Icon
  },
  Text: {
    ...antd_es.Text
  },
  PageHeader: {
    ...antd_es.PageHeader
  },
  Form: {
    ...antd_es.Form
  },
};

export default locale;