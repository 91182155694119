import React from 'react';

import { ReactComponent as FundraiseIcon } from 'assets/icons/icon-fundraise.svg';
import { ReactComponent as InitiativeIcon } from 'assets/icons/icon-initiative.svg';
import { ReactComponent as InformativeIcon } from 'assets/icons/icon-informative.svg';
import { ReactComponent as TrainingIcon } from 'assets/icons/icon-training.svg';
import { ReactComponent as CollectionIcon } from 'assets/icons/icon-collection.svg';

const ICONS = {
  collection: CollectionIcon,
  donation: FundraiseIcon,
  informative: InformativeIcon,
  volunteering: InitiativeIcon,
  volunteering_capacitation: TrainingIcon,
  volunteering_opportunity: InformativeIcon,
  volunteering_training: TrainingIcon,
  default: InitiativeIcon,
};

const InitiativeIconType = ({
  type,
  subtype,
  className = '',
}) => {
  const Icon = subtype
    ? (ICONS[`${type}_${subtype}`] || ICONS[type] || ICONS.default)
    : (ICONS[type] || ICONS.default)

  return <Icon className={className} />
}

export default InitiativeIconType;

