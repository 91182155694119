import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import './style.less';

const sdgNumbers = {
  'no-poverty': '1',
  'zero-hunger': '2',
  'good-health-and-wellbeing': '3',
  'quality-education': '4',
  'gender-equality': '5',
  'clean-water-and-sanitation': '6',
  'affordable-and-clean-energy': '7',
  'decent-work-and-economic-growth': '8',
  'industry-innovation-and-infrastructure': '9',
  'reduced-inequalities': '10',
  'sustainable-cities-and-communities': '11',
  'responsible-consumption-and-production': '12',
  'climate-action': '13',
  'life-below-water': '14',
  'life-on-land': '15',
  'peace-justice-and-strong-institutions': '16',
  'partnership-for-the-goals': '17',
};

const SdgMiniLogo = ({
  sdg,
  intl,
}) => {
  const t = intl.messages;
  return (
    <Tooltip title={t[`sdg_${sdg}`]}>
      <figure className={`sdgLogo ${sdg}`}>{sdgNumbers[sdg]}</figure>
    </Tooltip>
  );
}

export default injectIntl(SdgMiniLogo);
