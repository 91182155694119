import { useEffect, useState } from 'react';

const useNow = (interval = 60000) => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const tick = setInterval(() => setNow(Date.now()), interval);
    return () => { clearInterval(tick); }
  }, [ interval ]);

  return now;
};

export default useNow;
