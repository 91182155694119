import React from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

const EmptySearch = ({
  intl
}) => {
  return (
    <div className="EmptySearch">
      <img
        className="EmptySearch__img"
        src='/images/empty_search.svg'
        alt='empty-search'
      />
      <span className="EmptySearch__text">
        {intl.formatMessage({ id: "empty_search_message" })}
      </span>
    </div>
  );
}

export default injectIntl(EmptySearch);
