import React from "react";
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import ChatHeader from "./ChatHeader";
import ChatUsersList from "./ChatUsersList";

const CHAT_USERS_LIST_KEY = 'chatUsersList'

const { Panel } = Collapse;

const ChatUsersListWindow = ({
	useChat,
	setShowChatUsersList,
	searchUser,
  setSearchUser,
}) => {

	return (
		<Collapse
			defaultActiveKey={[CHAT_USERS_LIST_KEY]}
			className='GlobalChat__chatUsersListWindow'
			expandIconPosition="right"
		>
			<Panel
				key={CHAT_USERS_LIST_KEY}
				header={
					<ChatHeader
						onClose={() => setShowChatUsersList(false)}
						hasClose
            isNewChat
					/>
				}
			>
				{
					<ChatUsersList
						useChat={useChat}
						setShowChatUsersList={setShowChatUsersList}
						searchUser={searchUser}
						setSearchUser={setSearchUser}
					/>
				}
			</Panel>
		</Collapse>
	)
}

ChatUsersListWindow.propTypes = {
	searchUser: PropTypes.string,
	setSearchUser: PropTypes.func.isRequired,
	setShowChatUsersList: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
};

export default ChatUsersListWindow