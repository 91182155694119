import { useContext, createContext } from 'react';
const Context = createContext(new Set());
const { Provider } = Context;
const useFeatureList = () => useContext(Context);

const FeatureSwitch = ({
  feature,
  children,
}) => {
  const featureList = useFeatureList();

  const isActive = (typeof feature === 'function')
    ? feature(featureList)
    : featureList.has(feature);

  return isActive ? children : null;
}

export {
  Provider as FeatureProvider,
  useFeatureList,
  FeatureSwitch,
};
