import React, {useMemo} from "react";
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  EditOutlined,
  CloseOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { ConversationHeader } from "@chatscope/chat-ui-kit-react";
import { Avatar } from 'aplanet-ui-kit';


const ChatHeader = ({
  intl,
  totalUnreadCount = 0,
  avatar,
  username,
  onClose,
  onGoBack,
  onClickNewChat,
  isExpanded = false,
  isMyChat = false,
  isNewChat = false,
  hasGoBack = false,
  hasNewChat = false,
  hasClose = false,
}) => {

  const title = useMemo(() => {
    if (isMyChat) {
      return intl.formatMessage({ id: `chat_header_my_chat` })
    }
    if (isNewChat) {
      return intl.formatMessage({ id: `chat_header_new_chat` })
    }
    return username
  }, [
    intl,
    isMyChat,
    isNewChat,
    username,
  ]);

  return (
    <div className="GlobalChat__userHeader">
      {hasGoBack ? (
        <LeftOutlined
          className="GlobalChat__goBack"
          onClick={onGoBack}
        />
      ) : null}
      {isNewChat ? null : <Avatar src={avatar} name={username} />}
      <ConversationHeader.Content className="GlobalChat__headerTitle">
        {title}
      </ConversationHeader.Content>
      {hasNewChat ? (
        <EditOutlined
          className="GlobalChat__newChat"
          onClick={onClickNewChat}
        />
      ) : null}
      {hasClose ? (
        <CloseOutlined
          className="GlobalChat__closeChat"
          onClick={onClose}
        />
      ) : null}
      {totalUnreadCount > 0 && !isExpanded && <div className="GlobalChat__headerTotalUnread">{totalUnreadCount}</div>}
    </div>
  )
}

ChatHeader.propTypes = {
	totalUnreadCount: PropTypes.number,
	avatar: PropTypes.string,
	username: PropTypes.string,
	onClose: PropTypes.func,
	onGoBack: PropTypes.func,
	onClickNewChat: PropTypes.func,
	isExpanded: PropTypes.bool,
	isMyChat: PropTypes.bool,
	isNewChat: PropTypes.bool,
	hasGoBack: PropTypes.bool,
	hasNewChat: PropTypes.bool,
	hasClose: PropTypes.bool,
};

export default injectIntl(ChatHeader)