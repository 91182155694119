const NETWORKS = {
  linkedin: {
    regex: new RegExp('http(?:s)?://(?:[\w]+\.)?linkedin\.com/in/([A-z0-9_-]+)/?'), // eslint-disable-line no-useless-escape
    slugToUrl: (slug) => `https://www.linkedin.com/in/${slug}/`,
  },
};

export const slugToUrl = (network) => (slug) => {
  if(!NETWORKS[network]) {
    // FAIL
    return slug;
  }
  return NETWORKS[network].slugToUrl(slug);
};

export const urlToSlug = (network) => (url) => {
  const regex = NETWORKS[network].regex;

  if(!regex || regex.length === 0) {
    // FAIL
    return url;
  }

  const match = regex.exec(url);
  if(!match) {
    // FAIL
    return url;
  }

  return match[1] || null;
};

export const isValidUrl = (network) => (url) => {
  const regex = NETWORKS[network].regex;

  if(!regex || regex.length === 0) {
    // FAIL
    return false;
  }

  const match = regex.exec(url);
  return !!match;
};

